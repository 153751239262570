<template>
  <div class="login">
    <div class="mylogin" @click="submitForm()">
      <el-image
        class="left-image"
        :src="require('@/assets/images/login-left.jpg')"
      ></el-image>
      <div class="code-back">
        <div class="login-text">微信扫码登录</div>
        <div style="text-align: center">
          <el-image
            v-show="codeUrl != ''"
            class="code-image"
            :src="codeUrl"
          ></el-image>
          <div class="login-bottom-text">微信扫一扫，快速登录</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "../../../router/index";

import { getLoginCode, checkLoginCode, LogOut } from "@/utils/api/user";

export default {
  name: "Login",
  data: function () {
    return {
      codeUrl: "",
      timer: null,
      loginKey: "",
    };
  },

  created() {
    this.getCode();

    this.timer = window.setInterval(() => {
      setTimeout(() => {
        this.checkCode(); //调用接口的方法
        // console.log(1111111111);
      }, 0);
    }, 3000);
  },

  methods: {
    getCode() {
      var that = this;

      getLoginCode()
        .then((res) => {
          console.log(res, "res");
          if (res.status == 0) {
            // 已经登录，退出登录
            this.$message(res.msg);
            LogOut().then(() => {
              localStorage.removeItem("user");
              localStorage.removeItem("token");
              router.push("/login");
            });
          } else {
            that.codeUrl = res.data.code;
            that.loginKey = res.data.loginKey;
          }
        })
        .catch((error) => {
          console.log(error, "error");
        });
    },

    checkCode() {
      if (this.loginKey != "") {
        checkLoginCode({ loginKey: this.loginKey })
          .then((res) => {
            console.log(res, "轮询结果");
            if (res.msg.length > 1) {
              // 已授权
              var nstr = res.msg.split(",");
              var token = nstr[nstr.length - 1];
              console.log(token, "授权结果token");
              window.clearInterval(this.timer);

              if (token == "小程序码已失效，请刷新") {
                this.$message("小程序码已失效，请刷新");
              } else {
                localStorage.setItem("user", JSON.stringify("用户昵称"));
                localStorage.setItem("token", token);
                router.push("/home");
              }
            }
          })
          .catch((error) => {
            console.log(error, "error");
          });
      }
    },
    submitForm() {
      return;
      // localStorage.setItem("user", JSON.stringify("2222"));
      // router.push("/");
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  width: 100vw;
  padding: 0;
  margin: 0;
  height: 100vh;
  font-size: 16px;
  background-position: left top;
  background-color: #f6f7fa;

  position: relative;
}

.mylogin {
  width: 815px;
  height: 502px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  background: #ffffff;
  box-shadow: 0px 5px 16px 0px rgba(0, 0, 0, 0.15);

  border-radius: 15px;
  overflow: hidden;
}

.left-image {
  width: 371px;
  height: 502px;
}

.code-back {
  width: 498px;
  height: 502px;
}

.login-text {
  font-size: 22px;
  font-weight: Bold;
  text-align: left;
  color: #2a3762;
  line-height: 29px;
  margin-left: 28px;
  margin-top: 75px;
}

.code-image {
  width: 180px;
  height: 177px;
  margin-top: 59px;
}

.login-bottom-text {
  font-size: 14px;
  margin-top: 18px;
  color: #596178;
}
</style>

